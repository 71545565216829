import { RewindStitcherVideoJsPlayer } from 'components/RewindCamPlayer/plugins/rewindStitcher/src/rewindStitcher.types';
import useIsTouchDevice from 'hooks/useIsTouchDevice';
import { throttle } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useCamAdRunning from 'utils/useGlobalAdRunningState';
import type VideoJsPlayer from 'video.js/dist/types/player';

const TIMEOUT = 5000;
const useCamControls = (
  playerEventTriggerEl: HTMLDivElement | null,
  camPlayer: RewindStitcherVideoJsPlayer | VideoJsPlayer | null,
  isThumbnail: boolean = false,
  isScrubbing: boolean = false,
) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [controlsVisibility, setControlsVisibility] = useState({
    staticControlsVisible: true,
    primaryControlsVisible: true,
  });

  const nonTouchUserActive = useRef(false);

  const userScrubbingRef = useRef(isScrubbing);

  const isTouchDevice = useIsTouchDevice();

  const setShowControlsTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const hidePrimaryControlsTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleContextMenu = useCallback((event) => {
    // Disable the context menu so the user can't enable the native HTML5 video controls
    event.preventDefault();
    return false;
  }, []);

  const clearControls = useCallback(() => {
    if (userScrubbingRef.current) {
      return;
    }

    setControlsVisibility({
      staticControlsVisible: false,
      primaryControlsVisible: nonTouchUserActive.current,
    });
  }, []);

  const resetAndHideControlsOnTimeout = useCallback(() => {
    if (setShowControlsTimeout?.current) {
      clearTimeout(setShowControlsTimeout.current);
    }

    setShowControlsTimeout.current = setTimeout(clearControls, TIMEOUT);
  }, [clearControls]);

  const [camAdRunning] = useCamAdRunning();

  useEffect(() => {
    if (camAdRunning) {
      setControlsVisibility({
        staticControlsVisible: false,
        primaryControlsVisible: false,
      });
    }
  }, [camAdRunning]);

  useEffect(() => {
    userScrubbingRef.current = isScrubbing;

    if (!isScrubbing) {
      resetAndHideControlsOnTimeout();
    }
  }, [isScrubbing, resetAndHideControlsOnTimeout]);

  const handleUserActive = useMemo(
    () =>
      throttle(
        () => {
          if (hidePrimaryControlsTimeout.current) {
            clearTimeout(hidePrimaryControlsTimeout.current);
          }

          nonTouchUserActive.current = true;
          setControlsVisibility({
            staticControlsVisible: true,
            primaryControlsVisible: true,
          });

          resetAndHideControlsOnTimeout();
        },
        500,
        { leading: true, trailing: false },
      ),
    [setControlsVisibility, resetAndHideControlsOnTimeout],
  );

  const hidePrimaryControlsOnTimeout = useCallback(() => {
    if (hidePrimaryControlsTimeout.current) {
      clearTimeout(hidePrimaryControlsTimeout.current);
    }

    hidePrimaryControlsTimeout.current = setTimeout(() => {
      nonTouchUserActive.current = false;
      setControlsVisibility((stateControlsVisibility) => ({
        staticControlsVisible: stateControlsVisibility.staticControlsVisible,
        primaryControlsVisible: false,
      }));
    }, 500);
  }, [setControlsVisibility]);

  const handleUserInactive = useMemo(
    () =>
      throttle(() => {
        hidePrimaryControlsOnTimeout();

        resetAndHideControlsOnTimeout();
      }),
    [resetAndHideControlsOnTimeout, hidePrimaryControlsOnTimeout],
  );

  const handleTouchToggle = useCallback(
    (event) => {
      if (
        !event.target.parentElement.classList.contains('video-js') &&
        !event.target.parentElement.classList.contains('vjs-poster')
      ) {
        resetAndHideControlsOnTimeout();
        return;
      }

      setControlsVisibility((stateControlsVisibility) => {
        resetAndHideControlsOnTimeout();

        return {
          staticControlsVisible: true,
          primaryControlsVisible: !stateControlsVisibility.primaryControlsVisible,
        };
      });
    },
    [setControlsVisibility, resetAndHideControlsOnTimeout],
  );

  const handleSrcChange = useCallback(() => {
    setControlsVisibility((stateControlsVisibility) => ({
      staticControlsVisible: true,
      primaryControlsVisible: stateControlsVisibility.primaryControlsVisible,
    }));

    resetAndHideControlsOnTimeout();
  }, [resetAndHideControlsOnTimeout]);

  useEffect(() => {
    if (camPlayer && playerEventTriggerEl) {
      if (!isThumbnail) {
        if (initialLoad) {
          resetAndHideControlsOnTimeout();
          setInitialLoad(false);
        }
      }
    }
  }, [
    camPlayer,
    playerEventTriggerEl,
    isThumbnail,
    initialLoad,
    setInitialLoad,
    resetAndHideControlsOnTimeout,
  ]);

  useEffect(() => {
    if (camPlayer && playerEventTriggerEl) {
      if (!isThumbnail) {
        camPlayer?.on('contextmenu', handleContextMenu);
        camPlayer?.on('sourceset', handleSrcChange);

        if (!isTouchDevice) {
          playerEventTriggerEl.addEventListener('mousemove', handleUserActive);
          playerEventTriggerEl.addEventListener('mouseleave', handleUserInactive);
        } else {
          playerEventTriggerEl.addEventListener('touchstart', handleTouchToggle);
        }

        camPlayer.one('dispose', () => {
          camPlayer.off('contextmenu', handleContextMenu);
          camPlayer?.off('sourceset', handleSrcChange);

          playerEventTriggerEl.removeEventListener('mousemove', handleUserActive);
          playerEventTriggerEl.removeEventListener('mouseleave', handleUserInactive);
          playerEventTriggerEl.removeEventListener('touchstart', handleTouchToggle);

          if (setShowControlsTimeout.current) {
            clearTimeout(setShowControlsTimeout.current);
          }
        });
      } else {
        camPlayer.controls(false);
      }
    }
  }, [
    resetAndHideControlsOnTimeout,
    setInitialLoad,
    playerEventTriggerEl,
    camPlayer,
    isThumbnail,
    handleUserActive,
    handleUserInactive,
    handleContextMenu,
    isTouchDevice,
    handleTouchToggle,
    handleSrcChange,
  ]);

  return {
    controlsVisibility,
  };
};

export default useCamControls;
