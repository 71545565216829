import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads/dist/videojs.ads.css';
import 'videojs-ima/dist/videojs.ima.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
import 'videojs-hotkeys';
import '@montevideo-tech/videojs-cmcd';

const videoJsWrapper = (videoElement: HTMLElement, options: any) => videojs(videoElement, options);

export default videoJsWrapper;
